
/* #parent-parent-container::before {
  content: "";
  background-image: url("../../assets/cntusPrefered.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
} */
.improvedBurger{
  min-height: 4vmax;
}